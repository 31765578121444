<template>
  <div class="container mb-60 baojia-box liucheng-top rounded-lg">
    <v-row>
      <v-col   xl="8" lg="8" md="12" sm="12">
        <v-card flat class="pa-4" v-for="(item , index) in xianshitemai" :key="index">
          <div class="d-flex align-center justify-space-between">
            <div class="text-body-1 font-weight-bold">{{ item.name}}</div>
            <v-btn rounded outlined color="" class="grey--text" @click="lookMore(index)"
              >{{ $t("查看更多") }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-carousel
            :continuous="false"
            show-arrows-on-hover
            hide-delimiters
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            height="auto"
            class="temai-swpier"
            v-model="item.model"
          >
            <v-carousel-item v-for="num in Math.ceil(item.article.length/3)" :key="num">
              <v-row class="my-0">
                <template v-for="(n, k) in item.article">
                  <v-col
                    v-if="k + 1 > (num - 1) * 3 && k < num * 3"
                    :key="k"
                    xl="4"
                    lg="4"
                    md="6"
                    sm="6"
                  >
                    <v-hover v-slot="{ hover }">
                        <v-card :flat="hover ? false : true" class="mx-auto pa-4 cursor-pointer" @click="goDetail(n)">
                        <div>
                            <v-img contain width="246" height="184"  :src="n.face"></v-img>
                        </div>
                        <div class="text-body-1 font-weight-bold mt-1"> {{n.title}}</div>
                        <div class="mt-2 mb-2 line2 height-40" >{{n.content}} </div >
                        <v-divider></v-divider>
                        <v-card-actions> 
                        <span class="grey--text ">{{$t('通知')}}</span>
                        <v-spacer></v-spacer>
                        <div class="grey--text ">{{n.create_time | formFilter}}</div>
                        </v-card-actions>
                    </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col  xl="4" lg="4" md="12" sm="12">
        <v-card flat class="pa-4">
            <div class="text-body-1 font-weight-bold">{{$t('文章搜索')}}</div>
            <v-card flat color="#F2F2F2" class="d-flex mt-2">
                <input type="text" class="pa-2" style="width:100%;outline: none;" v-model="keyword" :placeholder="$t('输入关键字进行搜索')">
                <v-btn
                    class=""
                    height="36"
                    width="60"
                    depressed
                    color="primary"
                    @click="toserach"
                  >
                    <div>
                <v-img
                  width="24"
                  height="24"
                  class=""
                  src="@/assets/images/icon/icon_search.svg"
                  alt=""
                ></v-img>

              </div>
                  </v-btn>
            </v-card>
        </v-card>
        <v-card flat class="pa-4">
            <div class="d-flex justify-space-between align-center">
                <div class="text-body-1 font-weight-bold">{{$t('最新发布')}}</div>
                <div>
                    <v-btn text width="20"  min-width="20">

                    <v-icon @click="lefttoggle('left')">mdi-chevron-left</v-icon>
                    </v-btn>
                    {{this.pagearr.page}}/{{this.pagearr.pagetotal}}
                    <v-btn text width="20" min-width="20">

                    <v-icon  @click="lefttoggle('right')">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="d-flex cursor-pointer align-center mt-4" v-for="(item ,index) in Articledata" :key="index" @click="goDetail(item)">
              <div>
                <v-img class="mr-2 rounded"  width="80" height="80" :src="item.face"></v-img>

              </div>
                <div>
                    <div class="mb-2 line2">{{item.title}}</div>
                    <div class="grey--text">{{item.create_time | formFilter}}</div>
                </div> 
            </div>
            <empty :describe="$t('暂无搜索内容~')" :imgSrc="emptysrc" v-if="Articledata.length==0"></empty>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
import ArticleCart from "@/components/base/articleCard";
export default {
  components: {
    ArticleCart,
    empty
  },
  data() {
    return {
        remaimodel:"",
        tools:"",
        keyword:'',
        param:{},
      xianshitemai: [],
      Articledata:[],
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 3,
        pagetotal: 50,
        total: 0,
      },
      emptysrc:require('@/assets/images/other/wusousuo@2x.png')
    };
  },
  mounted() {
    this.param["keyword"] = this.keyword;
    this.param["page"] = this.pagearr.page;
    this.param["page_size"] = this.pagearr.pagenum;
    this.getdata()
    this.getnewArticle()
  },
  methods: {
    //获取返利攻略
    getdata(){
      this.$api.home.fanliStrategy().then((res)=>{
        this.xianshitemai=res.data
        for(var i=0 ; i<this.xianshitemai.length ; i++){
          this.$set(this.xianshitemai[i] ,'model', 'model'+i)
        }
      })
    }, 
    // 获取最新文章
    getnewArticle(){
      this.$api.home.latestRelease(this.param).then((res)=>{
        if(res.status=='success'){
          this.Articledata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
          this.Articledata=[]
          this.pagearr.pagetotal = 0;
        }
      })
    },
    lefttoggle(val){
      if(val=='left' && this.pagearr.page>1){
        this.pagearr.page--
        this.param['page']--
      }
      if(val=='right' && this.pagearr.page<this.pagearr.pagetotal){
        this.pagearr.page++
        this.param['page']++
      }
      this.getnewArticle()
    },
    toserach(){
      this.param["keyword"] = this.keyword;
      this.getnewArticle()
    },
    goDetail(val){
      this.$router.push({ path: '/strategyDetail' ,query:{id:val.id}})
    },
    // 点击查看更多
    lookMore(index){
      this.$router.push({ path: '/more-Strategy' ,query:{index}})
    },
  },
};
</script>
<style scoped>
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.height-40{
  height: 40px;
}
</style>